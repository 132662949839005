import React from "react";
import {Button, Dropdown, Form} from "semantic-ui-react";
import {DropdownItemProps} from "semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem";
import {DropdownProps} from "semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown";
import {api} from "../services/Api";
import {RouteComponentProps, withRouter} from "react-router";
import FileDownload from "js-file-download";

const MIN_YEAR = 2021;

export interface State {
    year: string;
    loading: boolean;
    errorMessage: string | null;
}

export const HistoricalTransactions = withRouter(
    class Component extends React.Component<RouteComponentProps<any>, State>{
        state = {
            year: new Date().getFullYear().toString(),
            loading: false,
            errorMessage: null
        }

        goBack = () => this.props.history.goBack();

        onSubmit = () => {
            const { year } = this.state;
            this.setState({loading: true, errorMessage: null});
            api.get("/transaction/all/processed", { params: { year }, responseType: 'blob' })
                .then(res => {
                    FileDownload(res.data, `transactions_${year}.csv`);
                    this.setState({loading: false});
                })
                .catch(e => {
                    console.error(e);
                    this.setState({loading: false, errorMessage: "An error occured with downloading. Please reach out to the developer."});
                })
        };

        onChangeYear = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
            this.setState({year: data.value as string});
        };

        getOptions = () => {
            const thisYear = new Date().getFullYear();
            let ret: DropdownItemProps[] = [];
            for (let i = MIN_YEAR; i <= thisYear; i++) {
                const yearAsString = i.toString();
                ret.push({
                    key: yearAsString,
                    text: yearAsString,
                    value: yearAsString
                });
            }
            return ret.reverse();
        };

        render() {
            const { loading, errorMessage, year } = this.state;
            return (
                <Form error={!!errorMessage}>
                    <a onClick={this.goBack} style={{cursor: "pointer"}}>Go Back</a>
                    <Form.Field>
                        <label>What year's transactions would you like to download?</label>
                        <Dropdown onChange={this.onChangeYear} options={this.getOptions()} value={year} />
                    </Form.Field>
                    <Button loading={loading} type={"submit"} onClick={this.onSubmit}>Submit</Button>
                </Form>
            );
        }
    }
);